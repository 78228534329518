/**
 * This file was automatically generated with 'graphql-code-generator' and should not be edited.
 * To regenerate this file update some *.graphql file and run `yarn codegen`
 *
 * See settings in project file: 'codegen.ts'
 */

/* eslint-disable */

import * as Types from '../../../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MedicalExaminationTypeDetailFragment = {
  __typename?: 'MedicalExaminationType';
  id: any;
  name: string;
  periodicityInMonths: number;
  meAllowedOperations: Array<Types.PermissionOperationChoices>;
  fromAge?: number | null;
  periodicityInMonthsFromAge?: number | null;
};

export type MedicalExaminationTypeDetailQueryVariables = Types.Exact<{
  medicalExaminationTypeDetailId: Types.Scalars['MedicalExaminationTypeID']['input'];
}>;

export type MedicalExaminationTypeDetailQuery = {
  __typename?: 'Query';
  object:
    | {
        __typename?: 'MedicalExaminationType';
        id: any;
        name: string;
        periodicityInMonths: number;
        meAllowedOperations: Array<Types.PermissionOperationChoices>;
        fromAge?: number | null;
        periodicityInMonthsFromAge?: number | null;
      }
    | { __typename?: 'ObjectDoesNotExistResponse'; objectId?: string | null };
};

export const MedicalExaminationTypeDetailFragmentDoc = gql`
  fragment MedicalExaminationTypeDetail on MedicalExaminationType {
    id
    name
    periodicityInMonths
    meAllowedOperations
    fromAge
    periodicityInMonthsFromAge
  }
`;
export const MedicalExaminationTypeDetailDocument = gql`
  query MedicalExaminationTypeDetail($medicalExaminationTypeDetailId: MedicalExaminationTypeID!) {
    object: medicalExaminationTypeDetail(id: $medicalExaminationTypeDetailId) {
      ... on ObjectDoesNotExistResponse {
        objectId
      }
      ... on MedicalExaminationType {
        ...MedicalExaminationTypeDetail
      }
    }
  }
  ${MedicalExaminationTypeDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class MedicalExaminationTypeDetailGQL extends Apollo.Query<
  MedicalExaminationTypeDetailQuery,
  MedicalExaminationTypeDetailQueryVariables
> {
  override document = MedicalExaminationTypeDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
